import Form from '@/library/Form';

export default {
    form: new Form({
        serial_no: '',
        note_type: 'dr',
        narration:'',
        ch_rev:"null",
        // journal_type_id: '',
        // particulars: [{ledger_head_id: '', drcr: 'dr', amount: 0}],
        ledger: [{
            ledger_head_id: '',
            ledger_head_name: '',
            total_amount: 0,
            tax: 0,
            net_amount: 0
        }],
        journal: ''
    }, '/api/drcr-note/ledgers'),
    download: false,
    tag: false,
    ledgerForm: new Form({
        name: '',
        description: '',
        account_head_id: ''
    }, '/api/ledger-head'),
    journal: {
        narration: null,
        ledger_head_id: null,
        entry_date: null,
        final_amount: null,
    },
    headers: [
        {text: 'Date', value: 'nepali_date', align: "left", sortable: false},
        {text: 'Particular', value: 'particular', align: "left", sortable: false},
        // { text: 'LF Id', value: 'lf_id', align: "center" },
        {text: 'Dr.', value: 'dr', align: "right", sortable: false},
        {text: 'Cr.', value: 'cr', align: "right", sortable: false},
        {text: 'User', value: 'user', align: "center", sortable: false},
    ],
    ledgerHeads: [], /** Ledger Heads **/
    journalTypes: [], /** Journal Types **/
    accountHeads: [], /** Account Heads **/
    drcrList: [
        {value: 'dr', text: 'DR'},
        {value: 'cr', text: 'CR'}
    ],


}
